@media only screen and (max-width:300px){
    .transferCardButtons{
       display:block !important;
       margin:15px 0px
    }

    .transferCardButtons > Button{
        margin:5px 0px
     }
   }


@media only screen and (max-width:600px){
 .transferCardButtons{
    display:flex;
    margin:15px 0px
 }

 .shareLink{
    font-size:16px;
    font-weight:600;
    margin:0px;
    padding:0px
}

.shareLinkDescription{
    margin-top:-20px;
    padding-top:-20px;
    font-size:12px;
    line-height: 0;
}

.shareLinkButton{
    background:#FBFBFB;
    padding:8px 10px;
    border-radius:12px;
    margin-top:24px
}

.searchUsers{
    margin-top:24px
}

.searchText{
    font-weight:500;
    font-size:15px
}

.exchangeButton{
    padding:0px 5px !important
}
}

@media only screen and (min-width:600px){
.shareLink{
    font-size:16px;
    font-weight:600;
    margin:0px;
    padding:0px
}

.shareLinkDescription{
    margin-top:-20px;
    padding-top:-20px;
    font-size:12px;
    line-height:0;
}

.shareLinkButton{
    background:#FBFBFB;
    padding:8px 10px;
    border-radius:12px;
    margin-top:24px
}

.searchUsers{
    margin-top:24px
}

.searchText{
    font-weight:500
}

.exchangeButton{
    padding:0px 20px !important
}

}

