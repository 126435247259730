@media only screen and (max-width: 600px) {
  .taskHistory {
    color: #06aeda !important;
    cursor: pointer !important;
  }

  .earnitCard {
    background-size: contain;
    /* background: url('../../Image/earnitlongcard.png'); */
  }

  .taskGrid {
    display: grid;
    grid-template-columns: auto;
  }
}

@media only screen and (min-width: 600px) {
  .taskHistory {
    color: #06aeda !important;
    cursor: pointer !important;
  }
  .taskGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .earnitCard {
    background-size: contain;
    background: url('../../Image/earnitlongcard.png');
  }
}

.taskAvatar {
  position: relative;
}

.statusBadge {
  position: absolute;
  top: 25px;
  right: 0;
  margin-left: -15px;
}


