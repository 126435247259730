@media only screen and (max-width:600px){
    .dashboardPage{
        max-width:700px;
        margin:auto;
        padding:30px 20px 70px 20px 
    }

    .dashboardRight{
        padding:15px 0px 0px 0px
    }

}


@media only screen and (min-width:600px){
    .dashboardPage{
        max-width:700px;
        margin:auto;
        padding:30px 30px 70px 30px 
    }
}

@media only screen and (min-width:768px){
    .dashboardPage{
        max-width:700px;
        margin:auto;
        padding:30px 30px 70px 30px 
    }   
}


@media only screen and (min-width:896px){
    .dashboardPage{
        max-width:800px;
        margin:auto;
        padding:30px 30px 70px 30px ;
       
    }
}


@media only screen and (min-width:1024px){
    .dashboardPage{
        max-width:90%;
        margin:auto;
        padding:30px 0px 70px 30px
    }

    .dashboardComponents{
        background:white
    }

    .dashboardCenter{
        border: 1px solid #e9eaeb;
        border-radius:10px;
        padding:30px;
        max-width:800px;
        margin:auto
    }

    .dashboardRight{
        max-width:800px
    }

}


@media only screen and (max-width:1199px){
.hideOnMobile{
    display:none
   }

}



@media only screen and (min-width:1199px){
    .dashboardPage{
        max-width:100%;
        display:grid;
        grid-template-columns: auto auto;
        padding:30px 0px 70px 0px;
    }

    .dashboardComponents{
        display:grid;
        grid-template-columns: 2fr 1fr;
    }
    
    .dashboardCenter{
        border: 1px solid #e9eaeb;
        border-radius:10px;
        padding:30px;
        max-width:700px;
        margin:auto
    }

    .dashboardRight{
        padding:10px 10px 10px 10px
    }
}



@media only screen and (min-width:1500px){
.dashboardCenter{
    border: 1px solid #e9eaeb;
    border-radius:10px;
    padding:30px;
    max-width:900px;
    margin:auto
}
}


@media only screen and (min-width:1700px){
    .dashboardPage{
        max-width:1500px;
        margin:auto;
        display:grid;
        grid-template-columns: auto auto;
        padding:30px 0px 70px 0px
    }

    .dashboardComponents{
        display:grid;
        grid-template-columns: 2fr 1fr;
    }
    
    .dashboardCenter{
        border: 1px solid #e9eaeb;
        border-radius:10px;
        padding:30px;
        margin:auto
    }

    .dashboardRight{
        padding:10px 10px 10px 10px
    }
}



/* dashboardPage - styling for the entire dashboard page, sidebar, dashboardCenter and dashboardRight */
/* dashboardComponents - styling for both dashboardCenter and dashboardRight */
