@media only screen and (max-width:300px) {
    .flexCard{
        display:block !important
    }
    .walletCard{
        background:#F2EEFD;
        /* background: url('../../Image/walletlongcard.png'); */
        border-radius:12px;
        padding:50px 32px;
        display:block !important;
        margin:26px 0px 0px 0px;
        object-fit: cover;
       
    }
    .walletCard p{
        margin:30px 0px 0px 0px;
        font-size:14px;
        font-weight:500
    }

    .eyeIcon{
        margin:10px 0px 0px 10px;
        cursor: pointer;
    }

    .walletButton{
        width:100% !important;
        margin:10px 0px;
        padding:27px 32px !important
    }
    .flexButtons{
        display:block !important
    }

    .friendsMinieMoney{
        display: flex;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .recentTransactions{
        margin:30px 0px 90px 0px
    }

}


@media only screen and (max-width:600px){
    .flexCard{
        display:flex
    }
    .walletCard{
        background:#F2EEFD;
        /* background: url('../../Image/walletlongcard.png'); */
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px;
        object-fit: cover;
        background-repeat: no-repeat;
        
    }
    .walletCard p{
        font-size:14px;
        font-weight:500
    }
    .walletCard h2{
        font-size:26px;
        font-weight:600
    }

    .viewAccount{
        color:#7A58EF
    }
    .walletButton{
        width:100% !important;
        padding:27px 32px !important
    }

    .sendButtons{
        margin:19px 0px
    }

    .flexWalletButtons{
        display: block;
       
    }

    .flexButtons{
        display:block !important;
    }
    .friendsMinieMoney{
        display: flex;
        justify-content: space-between;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .addFriendsButton{
        margin:20px 0px !important;
        padding:20px 32px !important
    }

    .recentTransactions{
        margin:30px 0px
    }
    .recentTransactionTitle{
        font-size: 20px;
        font-weight:medium
    }
}

@media only screen and (min-width:600px){
    .flexCard{
        display:flex
    }
    .walletCard{
        background:#F2EEFD;
        background: url('../../Image/walletlongcard.png');
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px;
        object-fit: cover;
    }
    .walletCard p{
        font-size:14px;
        font-weight:500
    }
    .walletCard h2{
        font-size:26px;
        font-weight:600
    }

    .viewAccount{
        color:#7A58EF
    }
    .walletButton{
        width:100% !important;
        padding:27px 32px !important
    }
    .flexWalletButtons{
        display:grid;
        grid-template-columns: auto auto auto;
    }
    .friendsMinieMoney{
        display: flex;
        justify-content: space-between;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .addFriendsButton{
        margin:20px 0px !important;
        padding:20px 32px !important
    }

    .recentTransactions{
        margin:30px 0px
    }
    .recentTransactionTitle{
        font-size: 20px;
        font-weight:medium
    }

    .eyeIcon{
        margin:20px 0px 0px 10px;
        cursor: pointer;
    }

    
}


@media only screen and (min-width: 1024px) {
}


@media only screen and (min-width: 1200px) {
}


.friendCard:hover{
    border: 1px solid #7A58EF;
}

.activeFriend{
    border: 1px solid #7A58EF;
}


.ElevateButton:hover{
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

}

.friendCard{
    background:#FBFBFB;
    padding:15px;
    border-radius: 8px;
    margin:0px 9px 0px 0px;
    width:200px;
    text-align: center;
}

.deposit{
    color:#22C55E;
    font-weight:500;
    font-size:14px;
    font-family:Arial, Helvetica, sans-serif
}

.kolosave{
    color:#669f2a;
    font-weight:500;
    font-size:14px;
    font-family:Arial, Helvetica, sans-serif
}

.withdrawal{
    color:#ff2000;
    font-weight:500;
    font-family:Arial, Helvetica, sans-serif;
    font-size:14px
}

.adult{
    color:#ff2000;
    font-weight:500;
    font-family:Arial, Helvetica, sans-serif;
    font-size:14px;
}

.earnit{
    color:#22C55E;
    font-weight:500;
    font-size:14px;
    font-family:Arial, Helvetica, sans-serif
}

.youngster{
    color:#22C55E;
    font-weight:500;
    font-size:14px;
    font-family:Arial, Helvetica, sans-serif
}



.transfer{
    color:#EF4444;
    font-weight:500;
    font-size:14px;
    font-family:Arial, Helvetica, sans-serif
}

.transactionList{
    border-bottom:1px solid #F3F3F4;
    transition:border 0.4s ease-in-out
}

.transactionList:hover{
    border-bottom:1px solid #06aeda ;
    border-top:1px solid #06aeda ;
    
}


.walletLinkButton{
    color:#7A58EF;
    cursor: pointer;
}