@media only screen and (max-width:1199px){
    .sideBar{
        display:none
    }
}

.navItem{
    padding:390px 0px !important
}


.logOutButton{
    display:flex !important;
    justify-content: space-between !important;
}