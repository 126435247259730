@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y:scroll
}


h1,h2,h3,h4,h5{
  font-family:"Outfit"
}



p,div,span{
  font-family: "Nunito";
}

code {
  font-family: "Outfit";
}


.tawk-bubble-container{
  display:none !important
}