.virtualAccountDescription{
    max-width:420px;
    margin:auto
}

.title{
    font-size:12px
}

.BankDetails{
    padding:12px 0px 10px 0px;
    border-bottom:1px solid #F3F3F4
}