.scrolltocard {
  display: none;
}

@media only screen and (max-width: 280px) {
  .titleSpan {
    font-size: 12px !important;
    line-height: -100px !important;
    display: none;
  }
  .walletCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    height: 200px;
    padding: 0px 0px 0px 10px;
    margin: 20px 0px;
    max-width:100%
  }

  .inactiveCardLeft {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }
  .inactiveCardRight {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }

  .inactiveCardLeft {
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }
  .inactiveCardRight {
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }

  .leftArrow {
    position: absolute !important;
    margin-top: 30px !important;
    left: 0;
    vertical-align: middle !important;
  }

  .rightArrow {
    position: absolute !important;
    right: 0 !important;
    vertical-align: middle !important;
    margin-top: 30px !important;
  }

  .walletBalance {
    background-image: url('../../../Image/walletCard.png');
    width: 200px;
    height: 100px;
    border-radius: 8px;
    flex: 0 0 auto;
    justify-content: space-between;
    color: #151618;
    padding: 50px 32px;
  }

  .savingsBalance {
    background-image: url('../../../Image/savingsCard.png');
    width: 200px;
    height: 100px;
    border-radius: 8px;
    flex: 0 0 auto;
    justify-content: space-between;
    color: #151618;
    padding: 50px 32px;
    object-fit: cover;
  }

  .balanceBalance {
    background-image: url('../../../Image/balanceCard.png');
    width: 200px;
    height: 100px;
    border-radius: 8px;
    flex: 0 0 auto;
    justify-content: space-between;
    color: #54595e;
    padding: 50px 32px;
  }

  .earnitBalance {
    background-image: url('../../../Image/earnitCard.png');
    width: 200px;
    height: 100px;
    border-radius: 8px;
    flex: 0 0 auto;
    justify-content: space-between;
    color: #54595e;
    padding: 50px 32px;
  }
}

@media only screen and (max-width: 600px) {
  .walletCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    height: 200px;
    padding: 0px 0px 30px 10px;
    margin: 20px 0px;
  }

  .inactiveCardLeft {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }
  .inactiveCardRight {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }

  .inactiveCardLeft {
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }
  .inactiveCardRight {
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }

  .leftArrow {
    position: absolute !important;
    margin-top: 60px;
    left: 0;
    vertical-align: middle !important;
  }

  .rightArrow {
    position: absolute !important;
    right: 0 !important;
    vertical-align: middle !important;
    margin-top: 60px;
  }

  .carsouselCircle {
    display: flex;
    justify-content: center;
  }

  .circle {
    border-radius: 50%;
    color: #c2ddf9;
    background-color: #c2ddf9;
    width: 8px;
    margin: 0px 9px;
    height: 8px;
    cursor: pointer;
    transition: width 1s;
  }

  .circleActive {
    width: 32px;
    background: #0073e5;
    border-radius: 10px;
  }

  .walletBalance {
    background-image: url('../../../Image/walletCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 20px;
  }

  .savingsBalance {
    background-image: url('../../../Image/savingsCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 20px;
    object-fit: cover;
  }

  .balanceBalance {
    background-image: url('../../../Image/balanceCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #54595e;
    padding: 50px 20px;
  }

  .earnitBalance {
    background-image: url('../../../Image/earnitCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #54595e;
    padding: 50px 20px;
  }

  .walletBalance h2,
  .balanceBalance h2,
  .earnitBalance h2,
  .savingsBalance h2 {
    font-size: 20px;
    font-weight: bold;
  }
}

@media only screen and (min-width: 600px) {
  .walletCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    height: 250px;
    padding: 0px 0px 30px 10px;
    margin: 20px 0px;
    max-width: 668px;
  }

  .inactiveCardLeft {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }
  .inactiveCardRight {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }

  .inactiveCardLeft {
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }
  .inactiveCardRight {
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }

  .leftArrow {
    position: absolute !important;
    margin-top: 60px;
    left: 0;
    vertical-align: middle !important;
  }

  .rightArrow {
    position: absolute !important;
    right: 0 !important;
    vertical-align: middle !important;
    margin-top: 60px;
  }

  .carsouselCircle {
    display: flex;
    justify-content: center;
  }

  .circle {
    border-radius: 50%;
    color: #c2ddf9;
    background-color: #c2ddf9;
    width: 8px;
    margin: 0px 9px;
    height: 8px;
    cursor: pointer;
    transition: width 1s;
  }

  .circleActive {
    width: 32px;
    background: #0073e5;
    border-radius: 10px;
  }

  .walletBalance {
    background-image: url('../../../Image/walletCard.png');
    width: 350px;
    height: 175px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 32px;
  }

  .savingsBalance {
    background-image: url('../../../Image/savingsCard.png');
    width: 350px;
    height: 175px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 32px;
    object-fit: cover;
  }

  .balanceBalance {
    background-image: url('../../../Image/balanceCard.png');
    width: 350px;
    height: 175px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 32px;
  }

  .earnitBalance {
    background-image: url('../../../Image/earnitCard.png');
    width: 350px;
    height: 175px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 32px;
  }

  .walletBalance h2,
  .balanceBalance h2,
  .earnitBalance h2,
  .savingsBalance h2 {
    font-size: 22px;
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) {
  .walletCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: 250px;
    padding: 0px 0px 30px 10px;
    margin: 20px 0px;
    max-width: 100%;
  }

  .inactiveCardLeft {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }
  .inactiveCardRight {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }

  .inactiveCardLeft {
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }
  .inactiveCardRight {
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }

  .leftArrow {
    position: absolute !important;
    margin-top: 60px;
    left: 0;
    margin-left: 210px !important;
  }

  .rightArrow {
    position: absolute !important;
    right: 0 !important;
    vertical-align: middle !important;
    margin-top: 60px;
    margin-right: 130px !important;
  }

  .carsouselCircle {
    display: flex;
    justify-content: center;
  }

  .circle {
    border-radius: 50%;
    color: #c2ddf9;
    background-color: #c2ddf9;
    width: 8px;
    margin: 0px 9px;
    height: 8px;
    cursor: pointer;
    transition: width 1s;
  }

  .circleActive {
    width: 32px;
    background: #0073e5;
    border-radius: 10px;
  }

  .walletBalance {
    background-image: url('../../../Image/walletCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 20px;
  }

  .savingsBalance {
    background-image: url('../../../Image/savingsCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: #151618;
    padding: 50px 10px;
    object-fit: cover;
  }

  .balanceBalance {
    background-image: url('../../../Image/balanceCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: black;
    padding: 50px 10px;
  }

  .earnitBalance {
    background-image: url('../../../Image/earnitCard.png');
    width: 300px;
    height: 150px;
    border-radius: 8px;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    color: black;
    padding: 50px 10px;
  }

  .walletBalance h2,
  .balanceBalance h2,
  .earnitBalance h2,
  .savingsBalance h2 {
    font-size: 22px;
    font-weight: bold;
  }
}


@media only screen and (min-width: 996px) {
  .leftArrow {
    position: absolute !important;
    margin-top: 60px;
    left: 0;
    margin-left: 210px !important;
  }

  .rightArrow {
    position: absolute !important;
    right: 0 !important;
    vertical-align: middle !important;
    margin-top: 60px;
    margin-right: 100px !important;
  }
}

@media only screen and (min-width: 996px) {
  .walletCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: 250px;
    padding: 0px 0px 30px 10px;
    margin: 20px 0px;
    max-width: 100%;
    position:relative
  }

  .walletCards::-webkit-scrollbar {
    display: none;
  }

  .inactiveCardLeft {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }
  .inactiveCardRight {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }

  .inactiveCardLeft {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    margin: 0px 0px 0px 0px;
    transition: transform 1s
  }
  .inactiveCardRight {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    margin: 0px 0px 0px 0px;
    transition: transform 1s
  }

  .leftArrow {
    position: absolute !important;
    margin-top: 80px !important;
    left: 0;
    margin-left: 390px !important;
    z-index:50 !important
  }

  .rightArrow {
    position: absolute !important;
    right: 0 !important;
    margin-top: 80px !important;
    margin-right: -130px !important;
    z-index:50 !important
  }

  .carsouselCircle {
    display: flex;
    justify-content: center;
  }

  .circle {
    border-radius: 50%;
    color: #c2ddf9;
    background-color: #c2ddf9;
    width: 8px;
    margin: 0px 9px;
    height: 8px;
    transition: width 1s;
  }

  .circleActive {
    width: 32px;
    background: #0073e5;
    border-radius: 10px;
  }

  .walletBalance {
    background-image: url('../../../Image/walletCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: #151618;
    z-index:1
  }

  .savingsBalance {
    background-image: url('../../../Image/savingsCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: #151618;
    object-fit: cover;
    z-index:1
  }

  .balanceBalance {
    background-image: url('../../../Image/balanceCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: black;
    z-index:1
  }

  .earnitBalance {
    background-image: url('../../../Image/earnitCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: black;
  }
}

@media only screen and (min-width: 1200px) {
  .walletCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: 260px;
    padding: 0px 0px 30px 10px;
    margin: 20px 0px 0px 0px;
    position:relative;
  }

  .inactiveCardLeft {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }
  .inactiveCardRight {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
  }

  .inactiveCardLeft {
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }
  .inactiveCardRight {
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    margin: 20px 0px 0px 0px;
    transition: transform 1s
  }

  .carsouselCircle {
    display: flex;
    justify-content: center;
  }

  .circle {
    border-radius: 50%;
    color: #c2ddf9;
    background-color: #c2ddf9;
    width: 8px;
    margin: 0px 9px;
    height: 8px;
    transition: width 1s;
  }

  .circleActive {
    width: 32px;
    background: #0073e5;
    border-radius: 10px;
  }

  .walletBalance {
    background-image: url('../../../Image/walletCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: #151618;
  }

  .savingsBalance {
    background-image: url('../../../Image/savingsCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: #151618;
    object-fit: cover;
  }

  .balanceBalance {
    background-image: url('../../../Image/balanceCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: black;
  }

  .earnitBalance {
    background-image: url('../../../Image/earnitCard.png');
    width: 400px;
    height: 200px;
    border-radius: 8px;
    flex: 0 0 auto;
    padding: 68px 32px;
    display: flex;
    justify-content: space-between;
    color: black;
  }

  .leftArrow {
    position: absolute !important;
    margin-top: 80px !important;
    left: 0;
    margin-left:405px !important;

  }

  .rightArrow {
    position: absolute !important;
    right: 1 !important;
    z-index:50 !important;
    margin-right:-265px !important;
    margin-top: 80px !important;
  }

  .walletBalance h2,
  .balanceBalance h2,
  .earnitBalance h2,
  .savingsBalance h2 {
    font-size: 32px;
    font-weight: bold;
  }
}

.eyeIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.walletIcon {
  width: 64px;
  height: 64px;
}





@media only screen and (min-width: 1500px) {
    .walletCards {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      height: 260px;
      padding: 0px 0px 30px 10px;
      margin: 20px 0px 0px 0px;
      
      position:relative
    }
  .leftArrow {
    position: absolute !important;
    margin-top: 80px !important;
    left: 0;
    margin-left:405px !important;

  }

  .rightArrow {
    position: absolute !important;
    right: 1 !important;
    z-index:50 !important;
    margin-right:-265px !important;
    margin-top: 80px !important;
  }
}

.activeCard {
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  margin: 0px 0px 0px 0px;
}


.hideEye{
  visibility: hidden;
}
