@media only screen and (max-width:600px){
    .navMenu{
        background:#FBFBFB;
        padding: 24px 5px;
        display:block;
        justify-content: space-between;
    }
    .freeAccount{
        background:#005FEB !important;
        padding:10px 12px;
        color:white;
        border-radius:64px;
        margin:10px 0px;
        display:none
    }

    .paymentContainer{
        display:flex;
        justify-content: center;
        padding:80px 0px;
    }

    .confirmPageContainer{
        width:90%;
        margin:auto;
        padding:80px 0px
    }
    
    .paymentPageContainer{
        width:90%;
        margin:auto
    }

    .confirmContainer{
        display:flex;
        justify-content: center;
        padding:40px 0px 0px 0px;
      
    }

    .sendMoneyBtn{
        background: #005FEB;
        border-radius:64px;
        width:100%;
        padding:20px 0px;
        color:white;
        text-align:center;
        cursor: pointer;
    }
}


@media only screen and (min-width:600px){
    .navMenu{
        background:#FBFBFB;
        padding: 24px 100px;
        display:flex;
        justify-content: space-between;
    }
    .freeAccount{
        background:#005FEB !important;
        padding:14px 24px;
        color:white;
        border-radius:64px
    }

    .paymentContainer{
        display:flex;
        justify-content: center;
        padding:80px 0px;
      
    }

    .confirmContainer{
        display:flex;
        justify-content: center;
        padding:40px 0px 0px 0px;
      
    }

    form{
        max-width:520px !important
    }

    .sendMoneyBtn{
        background: #005FEB;
        border-radius:64px;
        width:100%;
        padding:20px 0px;
        color:white;
        text-align:center;
        cursor: pointer;
    }
}


.errorMessage{
    margin:90px 0px;
    color:#0a2e65
}


.homeButton{
    background:#005FEB;
    padding:16px 32px;
    color:white;
    border-radius:100px
}

.confirmPageContainer{
    max-width:520px;
    margin:auto;
    padding:80px 0px
}

.paymentPageContainer{
    max-width:520px;
    margin:auto
}