@media only screen and (max-width:768px){
    .bannerBackground{
        display:none
    }
}


.bannerBackground{
    background:#E6EFFD;
    max-width:1200px;
    margin:20px auto
}

.bannerFlex{
    display: flex;
    width:900px
}


