.steps-container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.step {
  text-align: center;
  flex: 1;
  cursor: default;
  position: relative;
  font-size: 16px;
  max-width: calc(100% / 3);
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
}

.step:last-child {
  padding-right: 0;
}

.step:first-child {
  padding-left: 0;
}

.step-active .circle {
  color: #005feb;
  box-shadow: 0px 0px 0px 4px #e1e1fe;
  border: 2px solid #005feb;
  font-weight: 400;
}

.step-done .circle {
  background: #005feb;
  border: 2px solid #005feb;
  color: #eee;
}

.step-inactive .circle {
  background: white;
  border: 2px solid #e7e8e9;
  color: #000;
}

.step-active .label {
  color: #000;
}

.step-done .label {
  color: #222;
}

.step-inactive .label {
  color: #888;
}

.step-done .line {
  border-color: #3d84f5;
}

.step-inactive .line,
.step-active .line {
  border-color: #eee;
}

@media only screen and (max-width: 458px) {
  .steppersContainer {
    margin: 0px -20px;
  }
  .step .circle {
    padding: 16px;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 12px;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .step .line {
    height: 0px;
    border-width: 1px;
    border-style: solid;
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% + 19px);
    width: calc(100% - 10px);
  }
}

@media only screen and (min-width: 458px) {
  .steppersContainer {
    margin: 0px -58px;
  }
  .step .circle {
    padding: 17px;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 12px;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .step .line {
    height: 0px;
    border-width: 1px;
    border-style: solid;
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% + 19px);
    width: calc(100% - 10px);
  }
}
