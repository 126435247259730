@media only screen and (max-width:600px) {
    .bottomMenu{
        display:flex;
        position:fixed;
        bottom: 0;
        width: 100%;
       
        background:white;
        box-shadow: 1px 0px 0px 0px rgba(84, 71, 71, 0.322);
        padding:20px 0px 10px 0px;
        z-index:0
    }
    .bottomMenu >div{
        width:25%;
        text-align: center;
        align-items: center
    }

    .bottomMenu img{
        margin:auto;
       width:20px
    }

    .active{
        color:#0073E5
    }
    .walletActive{
        color:#7A58EF
    }
    .savingsActive{
        color:#D138F0
    }

    /* .bottomMenu >div >span{
        display:none
    } */
}

@media only screen and (min-width:600px) {
    .bottomMenu{
        display:flex;
        position:fixed;
        bottom: 0;
        width: 100%;
       
        background:white;
        box-shadow: 1px 0px 0px 0px rgba(84, 71, 71, 0.322);
        padding:20px 0px
    }
    .bottomMenu >div{
        width:25%;
        text-align: center;
        align-items: center
    }

    .bottomMenu img{
        margin:auto;
       width:30px
    }

    .active{
        color:#0073E5
    }

    /* .bottomMenu >div >span{
        display:none
    } */
}


@media only screen and (min-width:1199px) {
    .bottomMenu{
        display:none;
        position:fixed;
        bottom: 0;
        width: 100%;
       
        background:white;
        box-shadow: 1px 0px 0px 0px rgba(84, 71, 71, 0.322);
        padding:20px 0px
    }
    .bottomMenu >div{
        width:25%;
        text-align: center;
        align-items: center
    }

    .bottomMenu img{
        margin:auto;
       width:30px
    }

    .active{
        color:#0073E5
    }

    /* .bottomMenu >div >span{
        display:none
    } */
}

.active{
    color:#0073E5
}
.walletActive{
    color:#7A58EF
}
.savingsActive{
    color:#D138F0
}

.earnitActive,.quizActive{
    color:#06AEDA
}

