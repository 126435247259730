@media only screen and (max-width:600px){
    .notificationsCard{
        background: #FBFBFB;
        padding:64px 10px;
        display:flex !important;
        justify-content: space-between !important;
        text-align: center;
        border-radius: 16px;
    }
    .notificationItem{
        border:1px solid #F3F3F4;
        padding:24px 16px;
        margin:19px 0px;
        border-radius:16px;
        display: flex;
    }

    .dateStyle{
        font-size:10px;
        margin:10px auto
    }
  }
  
  
  @media only screen and (min-width:600px){
    .notificationsCard{
        background: #FBFBFB;
        padding:64px 30px;
        display:flex !important;
        justify-content: space-between !important;
        text-align: center;
        border-radius: 16px;
    }

      .notificationItem{
        border:1px solid #F3F3F4;
        padding:24px 16px;
        margin:19px 0px;
        border-radius:16px;
        /* display: flex; */
    }
    .dateStyle{
        font-size:10px
    }
  }

  
  
  @media only screen and (min-width:768px){
    .notificationsCard{
        background: #FBFBFB;
        padding:64px 92px;
        display:flex !important;
        justify-content: space-between !important;
        text-align: center;
        border-radius: 16px;
    }
  }