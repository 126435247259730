
@media only screen and (max-width:560px){
    .parentCard{
        padding:16px;
        border:1px solid #e0e0e0;
        border-radius:16px;
        margin:10px 0px;
    }

    .parentCard > *{
        margin-bottom: 0.75rem;
    }

    .parentCard h2{
        font-size:17px;
        font-weight:600
    }
    }


@media only screen and (min-width:560px){
.parentCard{
    padding:16px;
    border:1px solid #e0e0e0;
    border-radius:16px;
    margin:10px 0px;
    justify-content: space-between;
    display:flex
}

.parentCard h2{
    font-size:17px;
    font-weight:600
}
}

