.settingsTitle{
    background:#FBFBFB;
    border-radius:8px;
    padding:8px;
    color:#7F858D
}

.youngsterAvatarHeading{
    background:#FBFBFB;
    padding:16px;
    border-radius:12px;
    margin:20px auto
}

.youngsterSetting{
    background: #FBFBFB;
    padding:0px 12px;
    border-radius:8px;
    margin:10px 0px
}


.hideYoungsterAccount{
    height:0;
    visibility: hidden;
    display: none;
    transition:height 2s
}