@media only screen and (max-width:600px) {
    .react-tel-input .form-control {
        height: 1rem !important;
        letter-spacing: .01rem;
        border-radius: 5px !important;
        width: 100% !important;
        font-size: 0.9rem !important;
    }
    
}

@media only screen and (min-width:600px) {

    .react-tel-input {
        font-size: 0.9rem !important;
    }

    .react-tel-input .form-control {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        height: 1rem !important;
        position: relative;
        letter-spacing: .01rem;
        border-radius: 5px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 100% !important;
        font-size: 0.9rem !important;
    }
    
}



.inviteTitle{
    font-size:33px;
    font-weight:600
}

.inviteForm{
    padding:10px 0px 30px 0px 
}