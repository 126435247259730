.requestGrid{
    display:grid;
    grid-template-columns: auto auto auto;
    padding:0px 0px 0px 15px
}


.modal{
    z-index:100 !important
}


.footer{
    text-align:center;
    font-size:10px
}