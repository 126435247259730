.settingsTitle{
    background:#FBFBFB;
    border-radius:8px;
    padding:8px;
    color:#7F858D
}

.detailTitle{
color:#54595E;
font-size:15px;
margin:15px 0px

}

.brandcolor{
    color:#005FEB
}

.in-review{
    padding:6px 12px !important;
    background:#ecfaff !important;
    color:#0066f5 !important;
    border-radius:15px !important
}

.pending{
    padding:6px 12px !important;
    background:#F59E0B !important;
    color:white !important;
    border-radius:15px !important
}

.verified{
    padding:6px 12px !important;
    background:#22C55E !important;
    color:white !important;
    border-radius:15px !important
}

.verified{
    padding:6px 12px !important;
    background:#22C55E !important;
    color:white !important;
    border-radius:15px !important
}

.rejected{
    padding:6px 12px !important;
    background:#EF4444 !important;
    color:white !important;
    border-radius:15px !important
}

.invalid{
    padding:6px 12px !important;
    background:#EF4444 !important;
    color:white !important;
    border-radius:15px !important
}