.backIconButtonStyle{
    color: #0073e5 !important;
    background-color: #E6F1FC !important;
}

.nextButton{
    background-color:#5EA7EF !important;
    width:50% !important;
    max-width:332px
}

.nextButton:hover,.nextButton:focus{
 background-color: #0073E5 !important;
}

.nextButtonFull{
    background-color:#5EA7EF !important;
    width:100% !important;
    margin:20px 0px
}

.nextButtonFull:hover,.nextButtonFull:focus{
 background-color: #0073E5 !important;
}


.activeButton{
    background-color: #0073E5 !important;
    width:50% !important;
    max-width:332px
}

.activeButtonFull{
    background-color: #0073E5 !important;
    width:100% !important;
    
}


.inactiveButton{
    background-color:#5EA7EF !important;
}

.hiddenForm{
    display:none
}


.successForm{
    border:1px solid #86EFAC !important
}

.failureForm{
    border:1px solid #FCA5A5 !important
}


.scrollAvatar {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0px 0px 30px 10px;
  }


.flexAvatar{
    flex: 0 0 auto !important;
    cursor: pointer;
}

.imageOverlap{
    position:relative !important
}

.uploadImageIcon{
    position:absolute !important;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background:white;
    padding:10px;
    color:
    #D1D1D6;
    border-radius: 50%;
    border:1px dashed #D1D1D6;
    margin-bottom:-30px
}

.avatarSize{
    width:256px !important;
    max-width:256px !important;
    max-height:256px !important;
    height:256px !important;
    object-fit: cover !important;
    border: 4px solid #FFFFFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}


.journeyButton{
    background-color: #0073E5 !important;
    width:90% !important;
    padding:26px 32px;
    max-width:500px
}

.loginButton{
    padding:28px 32px;
    background-color: #0073E5
}

.positionForm{
    margin:0px 0px 0px -17px
}

.parentTitle{
    font-size:20px;
    font-weight: 600;
    padding:16px 0px 12px 0px
}


.emailVerificationCard{
    padding:32px 0px 48px 0px
}