@media only screen and (max-width:600px){

    .quoteCard{
        width:100%;
        background:#0073E5;
        /* height:140px; */
        border-radius:8px;
        color:white;
        padding:20px 20px;
        margin-bottom:10px
    }

    .quoteCard h2{
        font-size: 18px;
        font-weight:bold
    }

    .downloadApp{
        padding:20px 0px 0px 0px;
        font-size: 22px;
        font-weight:bold
    }

    .joinCommunity{
        padding:20px 0px;
    }

    .joinCommunity h2{
        font-size: 22px;
        font-weight:bold
    }
    .joinCommunity p{
        padding:10px 0px
    }

    .discordButton{
        margin:5px 0px !important;
        padding:30px !important
     }

     .blogCard h2{
        font-size: 22px;
        font-weight:bold
     }

     .articleChip{
        background-color: #FFF0F2;
        color:red;
        padding:2px 10px;
        border-radius: 4px;
     }
}



@media only screen and (min-width:600px){
  

    .quoteCard{
        width:100%;
        background:#0073E5;
        /* height:140px; */
        border-radius:8px;
        color:white;
        padding:20px 20px;
        margin-bottom:10px
    }

    .quoteCard h2{
        font-size: 18px;
        font-weight:bold
    }

    .downloadApp{
        padding:20px 0px 0px 0px;
        font-size: 22px;
        font-weight:bold
    }

    .joinCommunity{
        padding:20px 0px;
    }

    .joinCommunity h2{
        font-size: 22px;
        font-weight:bold
    }
    .joinCommunity p{
        padding:10px 0px
    }

    .discordButton{
        margin:5px 0px !important;
        padding:30px !important
     }

     .blogCard h2{
        font-size: 22px;
        font-weight:bold
     }

     .articleChip{
        background-color: #FFF0F2;
        color:red;
        padding:2px 10px;
        border-radius: 4px;
     }
}

@media only screen and (min-width:1010px){
   

}


@media only screen and (min-width:1200px){
   

    .quoteCard{
        width:100%;
        background:#0073E5;
        /* height:140px; */
        border-radius:8px;
        color:white;
        padding:20px 20px;
        margin-bottom:10px
    }

    .quoteCard h2{
        font-size: 18px;
        font-weight:bold
    }

    .downloadApp{
        padding:20px 0px 0px 0px;
        font-size: 22px;
        font-weight:bold
    }

    .joinCommunity{
        padding:20px 0px;
    }

    .joinCommunity h2{
        font-size: 22px;
        font-weight:bold
    }
    .joinCommunity p{
        padding:10px 0px
    }

    .discordButton{
        margin:5px 0px !important;
        padding:30px !important
     }

     .blogCard h2{
        font-size: 22px;
        font-weight:bold
     }

     .articleChip{
        background-color: #FFF0F2;
        color:red;
        padding:2px 10px;
        border-radius: 4px;
     }
}


.eventButton{
    padding:5px 20px;
    background:white;
    color:#0066f5;
    border-radius:30px;
    font-size:11px;
    margin:9px 0px
}


.event{
    font-size:11px;
    background:#ecfff6;
    width:50px;
    color:#006a31;
    text-align:center;
    margin:5px 0px;
    font-weight:500;
    border-radius:10px
}

.new{
    font-size:11px;
    background:#ff2000;
    max-width:50px;
    color:white;
    text-align:center;
    margin:5px 0px;
    font-weight:500;
    border-radius:10px;
    padding:0px 1px
}



.feature{
    font-size:11px;
    background:#ecfaff;
    width:50px;
    color:#0066f5;
    text-align:center;
    margin:5px 0px;
    font-weight:500;
    border-radius:10px
}

.announcementDescription{
    font-size:11px;
    margin:6px 0px
}