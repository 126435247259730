.Quiz{
    background:#E8F8F5;
    border-radius:12px;
    padding:50px 32px;
    display:block !important;
    margin:26px 0px 0px 0px;
    position: relative; 

}
.Quiz p{
    font-size:14px;
    font-weight:500
}
.Quiz h2{
    font-size:26px;
    font-weight:600
}
.kycList{
    position: relative;
}
@media only screen and (max-width:300px) {
    .flexCard{
        display:block !important
    }
    .QuizCard{
        background:#E8F8F5;
        border-radius:12px;
        padding:50px 32px;
        display:block !important;
        margin:26px 0px 0px 0px 
    }
    .QuizCard p{
        margin:30px 0px 0px 0px;
        font-size:14px;
        font-weight:500
    }

    .eyeIcon{
        margin:10px 0px 0px 10px;
        cursor: pointer;
    }

    .walletButton{
        width:100% !important;
        margin:10px 0px;
        padding:27px 32px !important
    }
    .flexButtons{
        display:block !important
    }

    .friendsMinieMoney{
        display: flex;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .recentTransactions{
        margin:30px 0px
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:20px 0px 0px 0px;
        position: relative
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:10px 0px
    }

    .featureGrid{
        display:grid;
        margin:0px 0px 90px 0px
    }

    .comingSoon{
        background:#E8F8F5;
        border-radius:24px;
        position:absolute;
        top:0;
        right:0;
        font-size:12px;
        padding:6px 10px;
        text-transform: uppercase;
        margin-top:-10px;
        color:#FAEBFE;
        font-weight:600
    }
    .flexCard img{
        width: 30px;
        height: 30px;
    }

}


@media only screen and (max-width:600px){
    .flexCard{
        display:flex;
        flex-direction: column;
        
    }
    .QuizCard{
        background:#E8F8F5;
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px 
    }
    .QuizCard p{
        font-size:14px;
        font-weight:500
    }
    .QuizCard h2{
        font-size:26px;
        font-weight:600
    }

    .viewAccount{
        color:#7A58EF
    }
    .walletButton{
        width:100% !important;
        padding:27px 32px !important
    }
    .flexButtons{
        display:flex
    }
    .friendsMinieMoney{
        display: flex;
        justify-content: space-between;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .addFriendsButton{
        margin:20px 0px !important;
        padding:20px 32px !important
    }

    .recentTransactions{
        margin:30px 0px
    }
    .recentTransactions h2{
        font-size: 20px;
        font-weight:medium
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:20px 0px 0px 0px;
        position: relative
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:10px 0px
    }

    .featureGrid{
        display:grid;
        margin:0px 0px 90px 0px
    }
    .comingSoon{
        background:#D138F0;
        border-radius:24px;
        position:absolute;
        top:0;
        right:0;
        font-size:12px;
        padding:6px 10px;
        text-transform: uppercase;
        margin-top:-10px;
        color:#FAEBFE;
        font-weight:600;
    }
    
    .GameIcon {
        width: 60px;
        height: 60px;
        margin: 14px 0px 0px 0px;
      }
      .flexCard img{
        width: 60px;
        height: 60px;
    }  
}

@media only screen and (min-width:600px){
    .flexCard{
        display:flex
    }
    .QuizCard{
        background:#E8F8F5;
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px 
    }
    .QuizCard p{
        font-size:14px;
        font-weight:500
    }
    .QuizCard h2{
        font-size:26px;
        font-weight:600
    }

    .viewAccount{
        color:#7A58EF
    }
    .walletButton{
        width:100% !important;
        padding:27px 32px !important
    }
    .flexButtons{
        display:flex
    }
    .friendsMinieMoney{
        display: flex;
        justify-content: space-between;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .addFriendsButton{
        margin:20px 0px !important;
        padding:20px 32px !important
    }

    .recentTransactions{
        margin:30px 0px
    }
    .recentTransactions h2{
        font-size: 20px;
        font-weight:medium
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:0px 0px 0px 0px;
        position: relative
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:10px 0px
    }

    .featureGrid{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin:20px 0px 0px 0px;
        grid-gap:20px
    }

     .comingSoon{
        background:#D138F0;
        border-radius:24px;
        position:absolute;
        top:0;
        right:0;
        font-size:12px;
        padding:6px 10px;
        text-transform: uppercase;
        margin-top:-10px;
        color:#FAEBFE;
        font-weight:600;
    }
    

    
}


@media only screen and (min-width: 1024px) {


    .iconSize{
        width:40px;
        height:40px;
        margin-right:20px
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:0px 0px 0px 0px;
        display:flex
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:0px 0px
    }

    .featureGrid{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin:20px 0px 0px 0px;
        grid-gap:20px

    }
    .flexCard img{
        width: 68px;
        height: 68px;
    }  
}