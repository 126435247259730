@media only screen and (max-width:600px){
  .createYoungster {
    padding: 37px 20px 130px 20px;
  }

  .youngsterTitle{
    margin-top:10px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .spaceForm{
    margin-bottom:19px
  }

  
  .aboutYoungster{
    font-size:20px;
    font-weight: bold;
  }
}


@media only screen and (min-width:600px){
  .createYoungster {
    padding: 37px 30px 13px 30px;
    width:667px;
  }

  .youngsterTitle{
    margin-top:-40px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .aboutYoungster{
    font-size:20px;
    font-weight: bold;
  }
}


@media only screen and (min-width:768px){
  .flexForm{
    display:flex;
    justify-content: space-between;
  }
  .spaceForm{
    margin-right:24px
  }
}


.createYoungsterForm label{
  font-weight: bold;
  font-size:12px
}

.brandColor{
  color:#0073E5;
  cursor: pointer;
}


.minielink{
  color:#7a58ef;
  text-decoration: underline;
}