

.App {
    text-align: center;
    scroll-behavior: smooth
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (min-width:1701px){
  .bigScreen{
    max-width:1500px !important;
    margin:auto !important
  }
  
  }
  
  
  html {
    scroll-behavior: smooth;
  }

  .helperText{
    color:#54595e;
    font-size:13px
  }





/* animations */

.pulsing-button{ 
  position: relative;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  animation: pulse 2s infinite;
  transform-origin: center !important;
  width:300px
}

@keyframes pulse {
  100% { transform: scale(1); width:200px !important }
  50% { transform: scale(1.2); width:300px !important };
  0% { transform: scale(1); }
  }