li{
    list-style: disc;
}

.instructionContainer{
    background:#FBFBFB;
    border-radius:16px;
    padding:30px !important;
    margin:0px 0px 30px 0px
}

.photoInstructionContainer{
    background:#FBFBFB;
    border-radius:16px;
    padding:30px !important;
    margin:20px 0px 30px 0px
}


.fileUploadContainer{
    background:#FBFBFB;
    border-radius:6px;
    display:flex;
    justify-content: center;
    margin:16px auto;
    padding:20px
}


.fileType{
    color:#AEB2B7
}