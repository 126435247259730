.sortable-handler {
    touch-action: none !important;
  }

.datePicker{
    appearance:inherit;
    -webkit-appearance: textfield !important
}

@media only screen and (max-width:600px){
    .appLogo{
        display: none;
    }
    .appText{
        font-size:25px !important;
        text-align: start !important;
        font-weight: bold;
        float: left;
        padding:0px 30px
    }
    .bgContainer{
        /* background-image: url("../../Image/Background.jpg"); */
        padding:30px 0px;
        width:100vw;
        height:100vh;
    }
    .formCard{
        padding:37px 28px;
        background:white;
        border-radius:16px
    }
    .formCard h2{
        font-size:32px;
        line-height: 27px;
        font-weight:600;
        padding:0px 0px 0px 0px
    }
    .formCard p{
        padding:14px 0px 
    }

    .formCard .selectTitle{
        font-size:19px;
        font-weight:500
    }

    .formCard .radioButtons{
        display:flex;
        justify-content: space-between;
        margin:8px 0px 20px 0px
    }

    .formCard .brandColor{
        color:#0073E5
    }

    .getStartedButton{
        margin:20px 0px;
        background:#0073E5 !important;
        color:white
    }

    .showLarge{
        display:none
    }
    
    .conditionsList{
        display:none;
        color:white
    }
    .copyrightText{
        display:none;
        color:white
    }
}


@media only screen and (min-width:600px){
    .appText{
        display: none !important;
    }
    .bgContainer{
        background-image: url("../../Image/Background.png");
        padding:100px 0px;
        width:100vw;
        height:100vh
    }
    .formCard{
        padding:37px 28px;
        background:white
    }
    .formCard h2{
        font-size:32px;
        line-height: 30px;
        font-weight:600;
        padding:0px 0px 0px 0px
    }
    .formCard p{
        padding:14px 0px 
    }

    .formCard .selectTitle{
        font-size:19px;
        font-weight:500
    }

    .radioSpace{
        margin-right:60px !important
    }

    .secondRadioSpace{
        margin-right:93px !important
    }

    input[type=radio]{
        cursor: pointer;
    }

    .formCard .radioButtons{
        display:flex;
        justify-content: space-between;
        margin:8px 0px 20px 0px
    }

    .formCard .brandColor{
        color:#0073E5
    }

    .getStartedButton{
        margin:20px 0px;
        background:#0073E5 !important;
        color:white
    }

    .showLarge{
        display:none
    }

    .conditionsList{
        display:none;
        justify-content: center;
        color:white
    }

    .copyrightText{
        display:none;
        color:white
    }

    .transitionImage{
       padding:10px 0px !important;
        height:200px !important;
        object-fit: cover !important;
    }

    .rotate {
        animation: rotation 10s infinite linear;
      }

      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
}

@media only screen and (min-width:768px){
    .bgContainer{
        background-image: url("../../Image/Background.png");
        padding:30px 0px;
        width:100vw;
        height:100vh
    }
    .formCard{
        padding:48px 40px;
        background:white;
        max-width:600px;
        margin:auto;
        border-radius:16px
    }
    .formCard h2{
        font-size:32px;
        line-height: 30px;
        font-weight:600;
        padding:0px 0px 0px 0px
    }
    .formCard p{
        padding:14px 0px 
    }

    .formCard .selectTitle{
        font-size:19px;
        font-weight:500
    }

    .formCard .radioButtons{
        display:flex;
        justify-content: space-between;
        margin:8px 0px 20px 0px
    }

    .formCard .brandColor{
        color:#0073E5
    }

    .getStartedButton{
        margin:20px 0px;
        background:#0073E5 !important;
        color:white
    }

    .accountTypeBoxes{
        display:flex
    }

    .accountTypeBox{
        box-shadow:0 1px 2px 0 rgba(0,0,0,0.2) !important;
        cursor:pointer;
        transition: box-shadow 0.7s;
        flex: 0 1 auto;
        width:100%;
        height:140px;
        margin-left:20px
    }
}

@media only screen and (min-width:996px){
    .bgContainer{
        background-image: url("../../Image/Background.png");
        padding:100px 0px;
        width:100vw;
        height:100vh
    }
    .formCard{
        padding:48px 40px;
        background:white;
        max-width:600px;
        margin:auto;
        border-radius:16px
    }
    .formCard h2{
        font-size:32px;
        line-height: 30px;
        font-weight:600;
        padding:0px 0px 0px 0px
    }
    .formCard p{
        padding:14px 0px 
    }

    .formCard .selectTitle{
        font-size:19px;
        font-weight:500
    }

    .formCard .radioButtons{
        display:flex;
        justify-content: space-between;
        margin:8px 0px 20px 0px
    }

    .formCard .brandColor{
        color:#0073E5
    }

    .getStartedButton{
        margin:20px 0px;
        background:#0073E5 !important;
        color:white
    }
}

@media only screen and (min-width:1200px){
    .bgContainer{
        background-image: url("../../Image/Background.png");
        padding:30px 0px;
        width:100vw;
        height:100vh;
        background-size: cover;
    }
    .formCard{
        padding:20px 40px 20px 40px;
        background:white;
        max-width: 600px;
        margin:0px;
        width:500px
    }
    .formCard h2{
        font-size:32px;
        line-height: 30px;
        font-weight:600;
        padding:4px 0px 0px 0px
    }
    .formCard .description{
        padding:7px 0px 15px 0px 
    }

    .formCard .selectTitle{
        font-size:19px;
        font-weight:500
    }

    .formCard .radioButtons{
        display:flex;
        justify-content: space-between;
        margin:8px 0px 20px 0px
    }

    .formCard .brandColor{
        color:#0073E5
    }

    .getStartedButton{
        margin:20px 0px;
        background:#0073E5 !important;
        color:white
    }

    .showLarge{
        display:inline;
        border-right:2px solid #C2DDF9
    }
    .formWithImage{
        display:flex;
        justify-content: center;
        padding:20px;
    }
   
    .conditionsList{
        display:flex;
        justify-content: center;
        color:white
    }

    .conditions{
        list-style: disc;
        margin:0px 0px 0px 40px
    }
    .copyrightText{
        display:block
    }
}





.accountTypeBox{
    box-shadow:0 1px 2px 0 rgba(0,0,0,0.2) !important;
    cursor:pointer;
    transition: box-shadow 0.7s;
}

.accountTypeBox:hover{
    box-shadow:0px 8px 12px 0px rgba(0,0,0,0.2) !important;
}





.signupImage{
    background:#005FEB;
    border-radius:50%;
    font-size:8px;
    right:0px;
    position:absolute;
    color:white;
    padding:1px 4px;
    margin-left: -20px;
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
    box-shadow: 0 0 0 2px #005FEB;
    background-color: #005FEB;
  }
  
  
  @-webkit-keyframes pulsing {
    to {
      box-shadow: 0 0 0 10px #005FEB;
    }
  }
  @-moz-keyframes pulsing {
    to {
      box-shadow: 0 0 0 10px #005FEB;
    }
  }
  @-ms-keyframes pulsing {
    to {
      box-shadow: 0 0 0 10px #005FEB;
    }
  }
  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 10px #005FEB;
    }
  }