@media only screen and (max-width: 500px) {
  .kycList {
    display: flex !important;
    justify-content: space-between;
    background: #FBFBFB;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .kycType {
    display: block !important;
    margin: 0px 0px 0px 0px;
  }

  .kycList > div > img {
    width: 30px;
    height: 30px;
    margin: 14px 0px 0px 0px;
  }

  .kycList .arrowIcon {
    text-align: right;
    cursor: pointer;
  }

  .youngsterAccount {
    border: 1px solid #f3f3f4;
    padding: 16px;
    border-radius: 12px;
  }
  .balanceText{
    width:200px;
    margin-top:6px;
    font-size:12px
  }
}

@media only screen and (max-width: 600px) {
  .kycType {
    display: flex;
    margin: 0px 0px 0px 0px;
  }

  .kycList {
    display: flex;
    justify-content: space-between;
    background: #FBFBFB;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .kycList > div > img {
    width: 30px;
    height: 30px;
    margin: 14px 0px 0px 0px;
  }

  .kycIcon {
    width: 30px;
    height: 30px;
    margin: 14px 0px 0px 0px;
  }

  .youngsterCard {
    background-color: #FBFBFB;
    text-align: center;
    padding: 32px 20px;
    border-radius: 16px;
  }

  .youngsterTaskCard {
    background-color: #FBFBFB;
    text-align: center;
    padding: 32px 20px;
    border-radius: 16px;
  }

  .usersFlex {
    width: 50px;
    margin: auto;
  }

  .youngsterAccount {
    border: 1px solid #f3f3f4;
    padding: 16px;
    border-radius: 12px;
    margin: 30px 0px;
  }

  .youngsterName {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0px 0px 10px;
  }

  .youngsterbalanceCards {
    display: grid;
    grid-template-columns: auto auto;
    grid-row: auto auto;
    grid-gap:5px;
    margin:18px 0px
  }

  .totalBalanceCard{
    background:#E6EFFD;
    padding:16px;
    border-radius:8px
  }

  .walletBalanceCard{
    background:#FAF0FF;
    padding:16px;
    border-radius:8px
  }

  .savingsBalanceCard{
    background:#F2EEFD;
    padding:16px;
    border-radius:8px
  }

  .earnitCard{
    background:#F5FEFF;
    padding:16px;
    border-radius:8px
  }

  .youngsterbalanceCards span{
    font-size:12px;
    line-height:0!important;
    font-weight:medium;
    margin:9px 0px
  }

  .youngsterbalancetext{
    font-size:15px;
    font-weight:500
  }

  .buttonsGrid{
    display:grid;
    grid-template-columns: auto auto;
    grid-template-columns: auto auto;
    grid-gap:10px 
  }

  .buttonText{
    font-size:14px !important
  }

  .sendMoneyMobile{
    margin:10px 0px
  }

}

@media only screen and (min-width: 600px) {
  .kycType {
    display: flex;
    margin: 0px 0px 0px 0px;
  }
  .kycText {
    margin: 0px 0px 0px 20px;
  }

  .kycList {
    display: flex;
    justify-content: space-between;
    background: #FBFBFB;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .kycList > div > img {
    width: 30px;
    height: 30px;
    margin: 14px 0px 0px 0px;
  }

  .kycIcon {
    width: 30px;
    height: 30px;
    margin: 14px 0px 0px 0px;
  }

  .youngsterCard {
    background-color: #FBFBFB;
    text-align: center;
    padding: 32px 20px;
    border-radius: 16px;
  }

  .youngsterTaskCard {
    background-color: #FBFBFB;
    text-align: center;
    padding: 32px 20px;
    border-radius: 16px;
  }

  .usersFlex {
    width: 50px;
    margin: auto;
  }

  .youngsterAccount {
    border: 1px solid #f3f3f4;
    padding: 16px;
    border-radius: 12px;
    margin: 30px 0px;
  }

  .youngsterName {
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0px 0px 10px;
  }

  .youngsterbalanceCards {
    display: grid;
    grid-template-columns: auto auto;
    grid-row: auto auto;
    grid-gap:16px;
    margin:18px 0px
  }

  .totalBalanceCard{
    background:#E6EFFD;
    padding:16px;
    border-radius:8px;
    display:flex
  }

  .walletBalanceCard{
    background:#FAF0FF;
    padding:16px;
    border-radius:8px;
    display:flex
  }

  .savingsBalanceCard{
    background:#F2EEFD;
    padding:16px;
    border-radius:8px;
    display:flex
  }

  .earnitCard{
    background:#F5FEFF;
    padding:16px;
    border-radius:8px;
    display:flex
  }

  .youngsterbalanceCards span{
    font-size:14px;
    line-height:0!important;
    font-weight:medium;
    margin:9px 0px
  }

  .youngsterbalancetext{
    font-size:20px;
    font-weight:500
  }

  .cardDetails{
    margin-left:12px
  }

  .balanceText{
    width:200px;
    margin-left:9px
  }

  .buttonsGrid{
    display:grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap:10px
  }
}

@media only screen and (min-width: 768px) {

  .kycList {
    display: flex;
    justify-content: space-between;
    background: #FBFBFB;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .kyc .kycList > div > img {
    width: 30px;
    height: 30px;
    margin: 14px 0px 0px 0px;
  }

  .kycList .arrowIcon {
    margin: 13px 0px 0px 0px;
    cursor: pointer;
  }

  .youngsterCard {
    background-color: #FBFBFB;
    text-align: center;
    padding: 32px 20px;
    border-radius: 16px;
  }

  .youngsterTaskCard {
    background-color: #FBFBFB;
    text-align: center;
    padding: 32px 20px;
    border-radius: 16px;
  }

  .usersFlex {
    width: 50px;
    margin: auto;
  }

  .youngsterText {
    width: 75%;
    margin: auto;
  }
}



.notificationsBadge{
  background:red;
  border-radius:50%;
  font-size:8px;
  right:0px;
  position:absolute;
  color:white;
  padding:1px 4px;
  margin-left: -20px;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 0 0 rgba(68, 67, 67, 0.7);
  background-color: red;
}


@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 5px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 5px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 5px rgba(232, 76, 61, 0);
  }
}
@keyframes pulsing {
  to {
    box-shadow: 0 0 0 5px rgba(232, 76, 61, 0);
  }
}


.link{
  color:#0080FF !important
}