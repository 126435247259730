.appContainer{
    height:100vh;
    position: relative;
}

.appImage{
    height:100vh !important;
    object-fit: cover !important;
    width:100vw !important
}

.buttons{
    position:absolute;
    bottom:0;
    width:100%;
   
}

.buttonContainer{
    padding:20px 20px 40px 20px;
}

.appButtons{
    padding:32px 32px !important;
    font-size:18px !important;
}

.bottomSheetContainer{
    position:absolute;
    background:white;
    height:0px;
    border-radius:35px 35px 0px 0px;
    width:100%;
    bottom:0;
    left:0;
    right:0;
    padding:20px 20px 0px 20px;
    transition: height 0.15s ease-out;
    visibility:hidden;
    display: none;
}

.displayBottomSheet{
    height:390px !important
}


.bottomSheetContainer h2{
    font-size:27px;
    font-weight:600;
    line-height: 27px;
}



.animate {
    animation: myanim 2.6s infinite;
  }
  
  @keyframes myanim {
    0% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.5;
    }

    50% {
        opacity: 1;
      }
  }



.appLoginContainer{
    margin:80px 20px
}

.appLoginForm h2{
    font-size:30px;
    font-weight:bold
}