.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999 !important;
  }
  
  .loader-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0066f5;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



.message{
  color:black;
  text-align:center;
  margin:0px 0px 0px 8px 
}