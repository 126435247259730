.checkbox-container{
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
}

.round {
    position: relative;
    width:24px   /* add spacing between radios */
  }
  
  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 4;
    width: 20px;
  }
  
  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 10px;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color: #004EC2;
    border-color: #004EC2;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  
  
  
.prefix{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight:500;
  font-size:15px
}


.phonePrefix{
  font-size:16px
}
  
  

  
 