@media only screen and (max-width: 300px) {
  .confirmButton {
    width: 100%;
    padding: 20px 32px;
  }

  .secondaryButton {
    width: 100%;
    padding: 20px 32px;
  }

  .confirmButton:hover {
    background: #0066f5 !important;
  }
}

@media only screen and (max-width: 600px) {
  .confirmButton {
    width: 60%;
    padding: 29px 32px;
  }

  .secondaryButton {
    width: 60%;
    padding: 29px 32px;
  }

  .confirmButton:hover {
    background: #0066f5 !important;
  }
}

@media only screen and (min-width: 600px) {
  .confirmButton {
    width: 60%;
    padding: 29px 32px;
  }

  .secondaryButton {
    width: 60%;
    padding: 29px 32px;
  }

  .confirmButton:hover {
    background: #0066f5 !important;
  }
}
