@media only screen and (max-width:300px) {
    .flexCard{
        display:block !important
    }
    .savingsCard{
        background:#FAEBFE;
        border-radius:12px;
        padding:50px 32px;
        display:block !important;
        margin:26px 0px 0px 0px 
    }
    .koloCard{
        background:#F0F5EA;
        border-radius:12px;
        padding:50px 32px;
        display:block !important;
        margin:26px 0px 0px 0px 
    }
    .koloCard p{
        margin:30px 0px 0px 0px;
        font-size:14px;
        font-weight:500
    }
    .savingsCard p{
        margin:30px 0px 0px 0px;
        font-size:14px;
        font-weight:500
    }

    .eyeIcon{
        margin:10px 0px 0px 10px;
        cursor: pointer;
    }

    .walletButton{
        width:100% !important;
        margin:10px 0px;
        padding:27px 32px !important
    }
    .flexButtons{
        display:block !important
    }

    .friendsMinieMoney{
        display: flex;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .recentTransactions{
        margin:30px 0px
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:20px 0px 0px 0px;
        position: relative
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:10px 0px
    }

    .featureGrid{
        display:grid;
        margin:0px 0px 90px 0px
    }

    .comingSoon{
        background:#FAEBFE;
        border-radius:24px;
        position:absolute;
        top:0;
        right:0;
        font-size:12px;
        padding:6px 10px;
        text-transform: uppercase;
        margin-top:-10px;
        color:#D138F0;
        font-weight:600
    }
}


@media only screen and (max-width:600px){

    .minieSavingsTitle h2{
        font-size:20px !important
    }
    .flexCard{
        display:flex
    }
    .savingsCard{
        background:#FAEBFE;
        /* background: url('../../Image/savingslongcard.png'); */
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px 
    }
    .savingsCard p{
        font-size:14px;
        font-weight:500
    }
    .savingsCard h2{
        font-size:26px;
        font-weight:600
    }
    .koloCard{
        background:#F0F5EA;
        /* background: url('../../Image/savingslongcard.png'); */
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px 
    }
    .koloCard p{
        font-size:14px;
        font-weight:500
    }
    .koloCard h2{
        font-size:26px;
        font-weight:600
    }

    .viewAccount{
        color:#7A58EF
    }

    .eyeIcon{
        margin:20px 0px 0px 10px;
        cursor: pointer;
    }
    .walletButton{
        width:100% !important;
        padding:27px 32px !important
    }
    .flexButtons{
        display:flex
    }
    .friendsMinieMoney{
        display: flex;
        justify-content: space-between;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .addFriendsButton{
        margin:20px 0px !important;
        padding:20px 32px !important
    }

    .recentTransactions{
        margin:30px 0px
    }
    .recentTransactions h2{
        font-size: 20px;
        font-weight:medium
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:20px 0px 0px 0px;
        position: relative
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:10px 0px
    }

    .featureGrid{
        display:grid;
        margin:0px 0px 90px 0px
    }
    .comingSoon{
        background:#FAEBFE;
        border-radius:24px;
        position:absolute;
        top:0;
        right:0;
        font-size:12px;
        padding:6px 10px;
        text-transform: uppercase;
        margin-top:-10px;
        color:#D138F0;
        font-weight:600;
        margin-right:16px
    }

    .circlePosition{
        margin:0px 0px 0px 3px
    }

  
}

@media only screen and (min-width:600px){
    .flexCard{
        display:flex
    }
    .savingsCard{
        background:#FAEBFE;
        background: url('../../Image/savingslongcard.png');
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px 
    }
    .savingsCard p{
        font-size:14px;
        font-weight:500
    }
    .savingsCard h2{
        font-size:26px;
        font-weight:600
    }
    .koloCard{
        background:#F0F5EA;
        /* background: url('../../Image/savingslongcard.png'); */
        border-radius:12px;
        padding:50px 32px;
        display:flex;
        justify-content: space-between;
        margin:26px 0px 0px 0px 
    }
    .koloCard p{
        font-size:14px;
        font-weight:500
    }
    .koloCard h2{
        font-size:26px;
        font-weight:600
    }

    .viewAccount{
        color:#7A58EF
    }
    .walletButton{
        width:100% !important;
        padding:27px 32px !important
    }
    .flexButtons{
        display:flex
    }
    .friendsMinieMoney{
        display: flex;
        justify-content: space-between;
        margin:40px 0px 0px 0px
    }

    .friendsMinieMoney h2{
        font-size:20px;
        font-weight:600
    }

    .friendsMinieMoney span{
        color:#7A58EF
    }

    .addFriendsButton{
        margin:20px 0px !important;
        padding:20px 32px !important
    }

    .recentTransactions{
        margin:30px 0px
    }
    .recentTransactions h2{
        font-size: 20px;
        font-weight:medium
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:0px 0px 0px 0px;
        position: relative
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:10px 0px
    }

    .featureGrid{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin:20px 0px 0px 0px;
        grid-gap:20px
    }

    .comingSoon{
        background:#FAEBFE;
        border-radius:24px;
        position:absolute;
        top:0;
        right:0;
        font-size:12px;
        padding:6px 10px;
        text-transform: uppercase;
        margin-top:-10px;
        color:#D138F0;
        font-weight:600;
        margin-right:16px
    }

    
}


@media only screen and (min-width: 1024px) {
    

    .iconSize{
        width:40px;
        height:40px;
        margin-right:20px
    }

    .featureCard{
        padding:20px 14px;
        border-radius:12px;
        margin:0px 0px 0px 0px;
        display:flex
    }

    .featureCard h2{
        font-size:19px;
        font-weight:500;
        padding:0px 0px
    }

    .featureGrid{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin:20px 0px 0px 0px;
        grid-gap:20px

    }

    .minieSavingsTitle{
        margin-left:20px
    }
}
.tag{
    
        background:#669F2A;
        border-radius:24px;
        position:absolute;
        top:0;
        right:0;
        font-size:12px;
        padding:6px 10px;
        text-transform: uppercase;
        margin-top:-20px;
        color:#ffff;
        font-weight:600;
        margin-right:86px
    
}


.checkbox input:checked{
    background-color: #669f2a !important;
}


.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
  }
  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }


.transitions{
    transition: width 0.3s ease-in-out !important;
}

.fadeout{
    opacity: 0.5;
    transition: opacity 0.5s ease-in
}

.fadein{
    opacity: 1;
    transition: opacity 0.5s ease-in
}


.greenBg{
    background:#4F7A21
}