.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .otp__{
    border-radius: 5px;
    font-size: 18px;
    outline: none;
  }
  
  .otp__:focus{
    border-width: 2px;
    border-radius: 7px;
    border:2px solid #005FEB !important
  }

  .transaction{
    border-radius: 5px;
    font-size: 18px;
    outline: none;
    color:#7A58EF;
    background:#F2EEFD;
    border: 1px solid #7A58EF !important
  }

  .transactionfocus{
    outline:0
  }


  .transactionfocus:focus{
    border-width: 2px;
    border-radius: 7px;
    border:2px solid #7A58EF !important
  }

  .transaction:focus{
    border: 3px solid #7A58EF !important;
    border-width: 2px;
    border-radius: 7px;
  }
  

.auth{
  border-radius: 5px;
  font-size: 18px;
  outline: none;
  color:#0066f5;
  background:#E6EFFD;
  border: 1px solid #0066f5 !important
}

.auth:focus{
  border: 2px solid #0066f5 !important; 
  border-width: 2px;
  border-radius: 7px;
}

.authfocus{
  outline:0
}


.authfocus:focus{
  border-width: 2px;
  border-radius: 7px;
  border:2px solid #0066f5 !important
}
