.frequency{
    border:solid 1px #EAECF0;
    border-radius:6px;
    padding:12px;
    display: flex
}

.week{
    border:solid 1px #EAECF0;
    border-radius:6px;
    padding:14px;
    display: flex;
    overflow-x: scroll;
}

.month{
    border:solid 1px #EAECF0;
    border-radius:6px;
    padding:14px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    overflow-x: scroll;
}

.chip{
    color:#004EC2;
    background-color: #ECF3FD;
    border-radius:16px;
    padding:4px 12px;
    text-align:center;
    margin:0px 14px 0px 0px;
    cursor: pointer;
    transition: background-color 0.5s ease; ;
}

.month .chip{
    color:#004EC2;
    background-color: #ECF3FD;
    border-radius:16px;
    padding:4px 12px;
    text-align:center;
    margin:0px 14px 10px 0px;
    cursor: pointer;
    transition: background-color 0.5s ease
}

.chipActive{
    background:#004EC2;
    color:white
}


.chipSelected{
    background:#71A6F4;
    color:white
}

.month .chipActive{
    background:#004EC2;
    color:white
}
