@media only screen and (max-width:300px) {
 .transferCards{
    background:#FBFBFB;
    padding:16px 0px 16px 0px !important;
    border-radius:8px;
    display:flex !important;
    justify-content: space-between;
    margin:24px 0px;
    cursor:pointer
}

.transferCardDetails{
    display:block !important;
    
}

.youngsterCard{
    background:#FBFBFB;
    border-radius:8px;
    padding:12px;
    display:block !important;
    margin:15px auto auto auto
}

.youngsterTaskCard{
    background:#FBFBFB;
    border-radius:8px;
    padding:12px;
    display:block !important;
    margin:15px auto auto auto
}

.youngsterName p{
    font-size:10px
}

.formContainer{
    margin:30px 0px 90px 0px
}

.confirmYoungsterDetails{
    text-align:center;
}

.confirmYoungsterDetails h2{
    font-size:15px;
    font-weight: 800;
}

.confirmYoungsterDetails p{
    font-size:10px;
    margin-bottom:10px
}

.confirmDetailsFlexButtons{
    display:block !important;
    justify-content:center !important;
    
}

 }



@media only screen and (max-width:600px) {
    
    .walletScreensContainer{
        margin:20px 10px 
    }
    .walletScreenTitle{
        margin:-39px 0px 0px 0px;
        font-size:20px;
        font-weight: 600;
    }

    .transferCards{
        background:#FBFBFB;
        padding:16px 8px 16px 8px;
        border-radius:8px;
        display:flex;
        justify-content: space-between;
        margin:24px 0px;
        cursor:pointer
    }

    .transferCardDetails{
        display:flex
    }
    .transferCards h2{
        font-size:14px;
        font-weight:700
    }

    .transferCards p{
        font-size:12px;
    }

    .youngsterCard{
        background:#FBFBFB;
        border-radius:8px;
        padding:12px;
        display:flex;
        margin:15px auto auto auto
    }

    .youngsterTaskCard{
        background:#FBFBFB;
        border-radius:8px;
        padding:12px;
        display:flex;
        margin:15px auto auto auto
    }

    .youngsterName{
        margin-top:-1px;
        text-align: start;
    }

    .youngsterName p{
        font-size:10px
    }

    .formContainer{
        margin:30px 0px 90px 0px
    }

    .confirmYoungsterDetails{
        text-align:center;
    }
    
    .confirmYoungsterDetails h2{
        font-size:15px;
        font-weight: 800;
    }
    
    .confirmYoungsterDetails p{
        font-size:10px;
        margin-bottom:10px
    }

    


    

    }
    @media only screen and (min-width:300px) {
        .confirmDetailsFlexButtons{
            display:flex !important;
            justify-content: space-between !important;
        }
    }
    @media only screen and (min-width:600px) {
     

        .walletScreensContainer{
            margin:20px 10px 
        }
        .walletScreenTitle{
            margin:-39px 0px 0px 0px;
            font-size:20px;
            font-weight: 600;
        }

        .transferCards{
            background:#FBFBFB;
            padding:16px;
            border-radius:8px;
            display:flex;
            justify-content: space-between;
            margin:24px 0px;
            cursor:pointer
        }
    
        .transferCardDetails{
            display:flex
        }
        .transferCards h2{
            font-size:14px;
            font-weight:700
        }
    
        .transferCards p{
            font-size:12px;
        }

        .youngsterCards{
            display:grid;
            grid-template-columns:auto auto;
            grid-template-rows: auto auto;
            width:100%;
            grid-gap:20px
        }

        .youngsterCard{
            background:#FBFBFB;
            border-radius:8px;
            padding:12px;
            display:flex;
            margin:15px 0px 0px 0px 0px
        }

        .youngsterTaskCard{
            background:#FBFBFB;
            border-radius:8px;
            padding:12px;
            display:flex;
            margin:15px 0px 0px 0px 0px
        }

        .youngsterName{
            text-align: start;
        }

        .youngsterName p{
            font-size:10px
        }
        
        .formContainer{
            margin:30px 0px 90px 0px
        }

        .confirmYoungsterDetails{
            text-align:start;
            display: flex;
            justify-content: center;
            margin:10px 0px
        }

        .confirmYoungsterDetails > div{
            margin:0px 0px 0px 10px
        }
        
        .confirmYoungsterDetails h2{
            font-size:15px;
            font-weight: 800;
            padding: 0;
            margin: 0;
        }
        
        .confirmYoungsterDetails p{
            font-size:10px;
            padding: 0;
            margin: 0;
           
        }

        .confirmDetailsFlexButtons{
            display:flex !important;
            justify-content: space-between !important;
        }

        .confirmDetailsBank{
            display:flex;
            justify-content: space-between;
        }



        }


    
    @media only screen and (min-width:768px) {
        .walletScreensContainer{
            margin:20px 10px 
        }
        .walletScreenTitle{
            margin:-39px 0px 0px 0px;
            font-size:20px;
            font-weight: 600;
        }

        .transferCards{
            background:#FBFBFB;
            padding:16px;
            border-radius:8px;
            display:flex;
            justify-content: space-between;
            margin:24px 0px;
            cursor:pointer
        }
    
        .transferCardDetails{
            display:flex
        }
        .transferCards h2{
            font-size:14px;
            font-weight:700
        }
    
        .transferCards p{
            font-size:12px;
        }

        .formContainer{
            margin:30px 0px 90px 0px
        }


        }
    
    
    @media only screen and (min-width:1025px) {
       

        .youngsterCard{
            height:79px;
            cursor: pointer;
        }

        .youngsterTaskCard{
            height:79px;
            cursor: pointer;
        }

        .youngsterCards{
            display:flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            height:120px;
            cursor: pointer;
        }
        .youngsterName h2{
            font-size:14px
        }
    }

.walletArrow{
    color:#7A58EF;
    background:#F2EEFD !important
}

.walletAccountArrow{
    color:#005FEB;
    background:#E6EFFD !important
}


.selectedCard,.youngsterCard:hover{
    background: #F2EEFD;
    border:1px solid #7A58EF;
    color:#7A58EF; 
}

.selectedTaskCard,.youngsterTaskCard:hover{
    background: #E6F7FB;
    border:1px solid #06AEDA;
    color:#06AEDA; 
}


.addBankLink{
    color:#7a58ef ;
    cursor: pointer;
    font-weight: bold;
}


.helpertext{
    font-size:12px
}

.titleHeader{
    font-size:15px
}